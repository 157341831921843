<template>
  <v-container fluid>
    <v-row :style="$vuetify.theme.dark ? 'background-color: #1E1E1E' : 'background-color: #FFF'" class="justify-center align-center">
      <v-col>
        <v-spacer></v-spacer>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          class="mx-auto"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <h1 :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" v-bind="attrs" v-on="on" style="width: 300px;font-size: xx-large;border-radius: 5px;">
              {{ date ? new Date(date).toLocaleDateString('esp',{ weekday :'short', day:'numeric',month:'short', year:'numeric' }).toLocaleUpperCase() : "CARGANDO..." }}
            </h1>
          </template>
          <v-date-picker
            v-model="date"
            :first-day-of-week="1"
            no-title
            scrollable
            event-color="error"
            @click:date="menu = false;"
          ></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row :style="$vuetify.theme.dark ? 'background-color: #1E1E1E' : 'background-color: #FFF'" class="justify-center align-center mb-16">
      <v-col cols="12" class="text-center">
        <div id="tabla">
        </div>
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirm" />

    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" fab @click="imprimir" large class="me-4">
        <v-icon>mdi-file-pdf-box</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="sendMail" large class="me-4">
        <v-icon>mdi-email-fast</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="exportar" large class="me-4">
        <v-icon>mdi-microsoft-excel</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import TableToExcel from "@linways/table-to-excel";
export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      menu: false,
      htmlData: false,
      sending: false,
      date: (new Date((Date.now() - ((new Date()).getTimezoneOffset() * 60000)) - (24*60*60*1000))).toISOString().substr(0, 10),
    };
  },
  watch:{
    date:{
      handler(v) {
        this.$set(this.$data, "date", this.date)
        this.getAproximaciones()
      },
    }
  },
  methods: {
    exportar(){
      var libroBodas = TableToExcel.tableToBook(document.getElementById("tabla").getElementsByTagName("table")[0], {
        sheet: {
          name: "APROXIMACIONES"
        }
      })
      TableToExcel.save(libroBodas, "APROXIMACIONES - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear() + ".xlsx")
    },
    async getAproximaciones(){
      const { data } = await axios({
        url: `/aproximaciones/${this.date}`,
      });
      document.getElementById("tabla").innerHTML = (data || "SIN DATOS")
    },
    async sendMail() {
      const confirmation =
        (await this.$refs.confirm.open(
          "Enviar correo",
          "¿Estás seguro de que quieres enviar un correo a varias personas?",
          { color: "primary" }
        ));
      if (!confirmation) return;

      try {
        this.sending = true;
        await Promise.all([
          axios({
            method: "post",
            url: `/aproximaciones/`,
            data: this.date
          }),
        ]);
        this.sending = false;

        this.$root.$emit("snack", "Se ha enviado el correo correctamente");
      } catch (err) {
        this.sending = false;
        // clearInterval(this.autosave)
        console.error(err);
        this.$root.$emit("snack", "No se ha podido enviar el correo");
      }
    },
    imprimir(){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>' + this.date + '</title><style>@page {margin: 0 };html {margin: 0 };ul {font-size: 14px !important}table:has(tbody:empty){ display: none !important }</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      v.document.write(document.getElementById("tabla").innerHTML)
      v.print()
      v.close()
    }
  },
  mounted() {
    this.getAproximaciones();
  },
};
</script>

<style>
</style>